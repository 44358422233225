import Helmet from 'react-helmet';

const RoomPage = () => {
    return (
        <div>
            <Helmet>
                <meta property='og:title' content='Skissan' />
                <meta
                    property='og:image'
                    content='https://skissan.com/og.png'
                />
                <meta
                    property='og:description'
                    content='Play online drawing games with your friends!'
                ></meta>
            </Helmet>
        </div>
    );
};

export default RoomPage;
